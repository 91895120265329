import React, { useState, useRef, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import { pipesToSpan } from "../util/common";
import VisibilitySensor from "react-visibility-sensor";
import PlusIcon from "../images/faq-plus.svg";
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../static/animations/ai-0/Sightfull_LP_Main.json";
//import HubspotForm from "react-hubspot-form";
import ParagraphImageLink from "../components/paragraphs/image-link";

const IndexPage = ({
  data: { node },
  pageContext: { isThankYou = false },
  ...props
}) => {
  //console.log('props', props)
  //console.log('isThankYou', isThankYou)

  const { pretitle, description, cta } = node;
  const {
    section1, // "paragraph__image_link"
    section3, // "paragraph__title_text_image"
    section4, // "paragraph__title_text_image"
    section5, // "paragraph__title_text_image"
    section6, // "paragraph__faq"
    video, // file_file
    thankyou, //"paragraph__generic_block"
  } = node.relationships;

  //console.log(node.relationships);

  const hero = {
    pretitle: pretitle,

    title: {
      src: node.relationships.image.localFile.publicURL,
      alt: node.image.alt,
    },
    image: {
      src: node.relationships.image.localFile.publicURL,
      alt: node.image.alt,
    },
    video: video,
    description: description,
    ctas: cta,
  };

  //console.log('hero', hero)

  const section2 = {
    description: node.section2Desc,
    title: node.section2title,
  };
  const seo = getMetatags(
    node.metatag,
    node.relationships.OGImage?.localFile?.publicURL
  );
  
  if (isThankYou) {
    seo.meta.push({
      name: "robots",
      content: "noindex, nofollow",
    });
  }
  

  return (
    <Layout
      dark={true}
      showCta={false}
      showFooter={false}
      showMiniFooter={isThankYou ? false : true}
    >
      <Seo {...seo} />

      {isThankYou && (
        <HeroThankyou
          {...thankyou}
          image={{
            alt: thankyou.image.alt,
            src: thankyou.relationships.image.localFile.publicURL,
          }}
        />
      )}

      {!isThankYou && (
        <>
          <Hero {...hero} />

          <section className=" text-white py-16">
            <Appear>
              <ParagraphImageLink data={{ items: section1 }} />
              <div className="h-[1px] w-[19px] mx-auto bg-[#AFBACA]" />
            </Appear>
          </section>

          {/* <Section1 items={section1} /> */}

          <Section2 {...section2} />
          
          <Section3 items={section3} />
          <Section4 items={section4} />
          <Section5
            {...section5}
            image={{
              src: section5.relationships.image.localFile.publicURL,
              alt: section5.image.alt,
            }}
          />
          <Section6 {...section6} />
        </>
      )}
    </Layout>
  );
};

const Hero = ({
  title,
  pretitle,
  description,
  cta,
  image,
  ctas,
  video,
  ...props
}) => {
  //console.log(cta, ctas)

  const ref = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
    const framesNumber = 546;
    const start = 1;
    const end = 6300;

    const handleScroll = () => {
      const position = window.scrollY;
      //const elementPosition = ref2.current.getBoundingClientRect();
      //console.log('position', position)
      //console.log('elementPosition.y',elementPosition.y )
      //console.log(position,elementPosition.y )
      if (position < end) {
        const prec = (100 * (position - start)) / (end - start);
        const f = parseInt((prec * framesNumber) / 100);
        //console.log(position, parseInt(prec), f);
        //console.log();
        ref.current.goToAndStop(f + 3, true);
      } else {
        //console.log("p.y=", elementPosition.y);
        ref.current.goToAndStop(framesNumber, true);
        // if (elementPosition.y > 100) {
        //   ref.current.goToAndStop(0, true);
        // } else {
        //   ref.current.goToAndStop(framesNumber, true);
        // }
      }
    };

    ref.current.goToAndStop(3, true);

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="pt-40 pb-12 lg:py-24 lg:pt-52 bg-cover bg-no-repeat --bg-black bg-[url('/background/gradient-dark-2.png')]">
      <Appear>
        <div className="container flex flex-col max-w-[1170px] text-center space-y-24">
          <div className="space-y-8 max-w-[780px] mx-auto">
            <div className="text-[40px] font-medium text-[#AFBACA] ">
              {pretitle}
            </div>
            <h1 className="text-center">
              <img
                src={`${title.src}`}
                alt={`${title.alt}`}
                className="block mx-auto"
              />
            </h1>
            <div
              className="text-center  text-xl lg:text-[22px] text-light text-[#D7DFE9] leading-[130%]"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {/* <Waitinglist title={ctas.title}>
              <WaitinglistForm
                title={ctas.title}
                portalId="26165421"
                formId="2269eb33-4959-4d90-9d57-37cd672a4556"
              />
            </Waitinglist> */}
          </div>

          <div className="mx-auto h-[5000px]">
            <div className="sticky top-[100px] " ref={ref2}>
              <Lottie
                animationData={groovyWalkAnimation}
                loop={false}
                lottieRef={ref}
              />
            </div>
          </div>
        </div>
      </Appear>
    </section>
  );
};

const HeroThankyou = ({ title, description, cta, image }) => {
  //console.log(cta);
  return (
    <section className="pt-40 py-12 lg:py-24 lg:pt-72  bg-cover bg-no-repeat bg-black h-screen bg-[url('/background/gradient-dark-2.png')]">
      <Appear>
        <div className="container flex flex-col  max-w-[1170px] text-center space-y-24">
          <div className="space-y-8 max-w-[780px] mx-auto">
            <div className="text-center">
              <img src={image.src} alt={image.alt} className="block mx-auto" />
            </div>
            <h1 className="text-[40px] font-medium text-[#AFBACA] ">{title}</h1>
            <div className="text-[#5E718D] text-center text-xl lg:text-[22px]  leading-[140%] font-light	">
              <div
                dangerouslySetInnerHTML={{ __html: description.processed }}
              />
            </div>

            <Link
              to={cta.uri}
              className="border border-[#4A72FF] py-3 px-6 rounded-[10px] text-[#94ABFF] font-semibold text-sm inline-block"
              style={{
                background:
                  "var(--fill, linear-gradient(0deg, rgba(27, 77, 255, 0.40) 0%, rgba(27, 77, 255, 0.40) 100%), radial-gradient(49.91% 52.74% at 50.09% 60.38%, rgba(20, 48, 148, 0.00) 0%, rgba(20, 48, 148, 0.30) 100%))",
                backdropFilter: "blur(2px)",
              }}
            >
              {cta.title}
            </Link>
          </div>
        </div>
      </Appear>
    </section>
  );
};

// const Section1 = ({ items }) => {
//   const cards = items.map((item, index) => (
//     <div key={index} className="lg:mx-auto px-20">
//       <img
//         src={item.relationships.image.localFile.publicURL}
//         height={item.image.height}
//         width={item.image.width}
//         alt={item.image.alt}
//         className="lg:mx-auto "
//       />
//     </div>
//   ));

//   return (
//     <section className=" text-white py-16">
//       <Appear>
//         <div className="container max-w-[1280px] space-y-12 ">
//           {/* <h2 className="text-3xl font-meduim mx-auto text-center">{title}</h2> */}

//           <div
//             style={{
//               background:
//                 "linear-gradient(90deg, rgba(45, 54, 67, 0.00) 0%, #2D3643 49.31%, rgba(45, 54, 67, 0.00) 100%)",
//             }}
//             className="h-[1px]"
//           />

//           <div className="flex flex-row justify-around  mx-auto  items-end  hidden lg:flex">
//             {cards}
//           </div>

//           <div className="flex flex-row flex-wrap gap-y-9 gap-x-3 justify-around  lg:hidden  items-end ">
//             {cards}
//           </div>

//           <div
//             style={{
//               background:
//                 "linear-gradient(90deg, rgba(45, 54, 67, 0.00) 0%, #2D3643 49.31%, rgba(45, 54, 67, 0.00) 100%)",
//             }}
//             className="h-[1px]"
//           />
//         </div>
//         <div className="h-[1px] w-[19px] mx-auto bg-[#AFBACA]" />
//       </Appear>
//     </section>
//   );
// };

const Section2 = ({ title, description }) => {
  return (
    <section className="pt-32 pb-16 ">
      <div className="container max-w-[1280px]">
        <Appear>
          <div
            className="py-20 px-8 lg:px-36 rounded-[70px] space-y-6 text-center"
            style={{
              background:
                "radial-gradient(82.74% 71.58% at 53.08% 0%, rgba(102, 134, 191, 0.40) 0%, rgba(61, 82, 122, 0.40) 22.74%, rgba(29, 42, 68, 0.40) 52.95%, rgba(5, 11, 27, 0.40) 100%)",
            }}
          >
            <h2
              className="text-[56px] leading-[72px] font-semibold text-[#AFBACA] tracking-[-2px]"
              dangerouslySetInnerHTML={{ __html: pipesToSpan(title, "tAI") }}
            ></h2>

            <div
              className="text-[22px] text-[#8897AE] font-light leading:[150%] lg:w-[800px] mx-auto"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </Appear>
      </div>
    </section>
  );
};

const Appear = ({ children, delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const onChange = (flag) => {
    if (!isVisible) {
      setIsVisible(flag);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div
        className={isVisible ? " opacity-100 " : " opacity-0 "}
        style={{
          transition: "all",
          //transitionProperty:"all",
          transform: isVisible ? "translateY(0)" : "translateY(100px)",
          transitionDuration: "1000ms",
          transitionDelay: `${delay * 200}ms`,
        }}
      >
        <div className=" ">{children}</div>
      </div>
    </VisibilitySensor>
  );
};

const Section5 = ({ title, description, image }) => {
  return (
    <section className="pt-24 lg:pt-32 pb-12 lg:pb-16 ">
      <div className="container max-w-[1280px]">
        <Appear>
          <div
            className="py-12 lg:py-20 px-12 lg:px-36 rounded-[70px] space-y-6 text-center"
            style={{
              background:
                "radial-gradient(82.74% 71.58% at 53.08% 0%, rgba(102, 134, 191, 0.40) 0%, rgba(61, 82, 122, 0.40) 22.74%, rgba(29, 42, 68, 0.40) 52.95%, rgba(5, 11, 27, 0.40) 100%)",
            }}
          >
            <div className="">
              <img
                alt={image.alt}
                src={image.src}
                className="block mx-auto max-w-[180px]"
              />
            </div>
            <h2
              className="text-[56px] leading-[72px] font-semibold text-[#AFBACA] tracking-[-2px]"
              dangerouslySetInnerHTML={{ __html: pipesToSpan(title, "tAI") }}
            ></h2>

            <div
              className="text-[22px] text-[#8897AE] font-light leading:[150%] lg:w-[600px] mx-auto"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </Appear>
      </div>
    </section>
  );
};

const Section6 = ({ title, relationships }) => {
  const [selected, setSelected] = useState(-1);

  const items = relationships.questions.map((item, index) => (
    <div className="" key={index}>
      <div className="flex flex-row justify-between items-top  py-10 pl-4 lg:pl-20 text-[#8897AE]">
        <div className="space-y-6  w-full ">
          <button
            className="w-full text-left  text-[20px] font-light"
            onClick={() => setSelected(selected === index ? -1 : index)}
          >
            {item.question}
          </button>
          {selected === index && <div className="">{item.answer}</div>}
        </div>

        <div className="">
          <button
            onClick={() => setSelected(selected === index ? -1 : index)}
            className="flex flex-row justify-around items-center w-10 h-10  "
          >
            <img
              src={PlusIcon}
              className={
                "transition " + (selected === index ? " rotate-45" : " ")
              }
              alt="plus"
            />
          </button>
        </div>
      </div>
      <div
        className="h-[1px] "
        style={{
          background:
            "linear-gradient(90deg, rgba(45, 54, 67, 0.40) 0%, #2D3643 49.31%, rgba(45, 54, 67, 0.00) 100%, rgba(45, 54, 67, 0.40) 100%)",
        }}
      />
      <div className="bg-[#AFBACA] w-[19px] h-[1px] ml-4 lg:ml-20" />
    </div>
  ));
  return (
    <section className="py-12 lg:py-24 lg:pb-72">
      <div className="container max-w-[1000px] ">
        <h2 className="text-[42px] font-semibold mb-6 text-[#AFBACA] ml-20">
          {title}
        </h2>
        <div
          className="h-[1px] "
          style={{
            background:
              "linear-gradient(90deg, rgba(45, 54, 67, 0.40) 0%, #2D3643 49.31%, rgba(45, 54, 67, 0.00) 100%, rgba(45, 54, 67, 0.40) 100%)",
          }}
        />
        <div className="bg-[#AFBACA] w-[19px] h-[1px] ml-4 lg:ml-20" />
        <div>{items}</div>
      </div>
    </section>
  );
};

const Card = ({ title, image, description, className }) => {
  return (
    <div className={className}>
      <div>
        <img src={image.src} alt={image.alt} />
      </div>
      <h3 className="font-medium">{title}</h3>
      <div
        className="text-[#8897AE] font-light leading-[150%]"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

const Sperator = () => {
  return (
    <div className="relative hidden lg:block">
      <div
        className="w-[1px] h-[276px]"
        style={{
          background:
            "linear-gradient(90deg, #2D3643 0%, rgba(45, 54, 67, 0.00) 100%)",
        }}
      />

      <div className="w-[1px] h-[19px] bg-[#AFBACA] absolute top-[80px]" />
    </div>
  );
};

const Section3 = ({ items }) => {
  const cards = items.map((card, index) => (
    <React.Fragment key={index}>
      <Appear delay={index}>
        <Card
          {...card}
          image={{
            src: card?.relationships?.image?.localFile?.publicURL,
            alt: card?.image?.alt,
          }}
          className="lg:max-w-[320px] space-y-5 text-lg text-[#F9FAFB]"
        />
      </Appear>
      {index < items.length - 1 && <Sperator />}
    </React.Fragment>
  ));

  return (
    <section className="pt-12 pb-4 lg:py-24 text-white">
      <div className="container max-w-[1280px]">
        <div className="flex flex-col lg:flex-row justify-between space-y-10 lg:space-y-0">
          {cards}
        </div>
      </div>
    </section>
  );
};

const Card2 = ({
  title,
  image,
  description,
  video,
  focus,
  positionInCard,
  index,
  count,
  className,
}) => {
  const ref = useRef();
  //const ref2 = useRef();

  //const isFirst = index === 0
  const isLast = index + 1 === count;

  //console.log(index, isFirst, isLast);

  // const onChange = (flag) => {
  //   console.log(flag)
  //   if (flag) {
  //     ref.current.play();
  //   }
  // };

  useEffect(() => {
    if (focus) {
      ref.current.play();
    }
  }, [ref, focus]);

  const pos = 130 - (200 - 120) * positionInCard;
  let opacity = positionInCard > 0.6 ? 1 - positionInCard : 1.0; // - ( 0.4 ) * positionInCard

  opacity = !focus ? 1.0 : isLast ? 1.0 : opacity;

  return (
    <div
      className={`flex flex-col lg:flex-row justify-between space-y-6 lg:space-y-0 h-[353px] sticky top-[220px] ${
        focus ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="lg:w-1/3  ">
        <div
          className=" space-y-10  card-text"
          style={{
            transform: `translateY(${pos}px)`,
            opacity: `${opacity}`,
          }}
        >
          <div
            className="text-[48px] font-semibold leading-[90%] text-[#AFBACA]"
            dangerouslySetInnerHTML={{ __html: pipesToSpan(title, "tAI") }}
          />
          <div className="text-[#8897AE] text-lg leading-[150%] font-light">
            {description}
          </div>
        </div>
      </div>
      <div className="lg:w-7/12 ">
        <div className={"  h-full flex flex-row items-center justify-between"}>
          {image.src && (
            <img
              src={image.src}
              alt={image.alt}
              className="block mx-auto  max-w-[460px]"
            />
          )}

          {video && (
            // <VisibilitySensor onChange={onChange} partialVisibility>
            <video
              width="100%"
              muted
              ref={ref}
              autoPlay
              className=" --max-h-[353px] max-w-[460px] block mx-auto "
            >
              <source src={video} type="video/mp4" />
              Download the <a href={video}>MP4</a> video.
            </video>
            // </VisibilitySensor>
          )}
        </div>
      </div>
    </div>
  );
};

const Section4 = ({ items }) => {
  const [cardIndex, setCardIndex] = useState(null);
  const [positionInCard, setCPositionInCard] = useState(0);
  const ref = useRef(null);

  const cards = items;

  useEffect(() => {
    const handleScroll = (e) => {
      const sticky = 220;
      const cardHeight = 353;

      const pos =
        (window.scrollY + sticky - ref.current.offsetTop) / cardHeight;

      const newIndex = Math.min(
        Math.max(
          0,
          Math.floor(
            (window.scrollY + sticky - ref.current.offsetTop) / cardHeight
          ) // 100 represents card height
        ),
        cards.length - 1
      );

      const posInCard = Math.min(Math.max(0, pos - newIndex), 1);

      //console.log( pos, posInCard )
      //console.log(deltaY , previousScrollY, newIndex)
      //console.log('newIndex=', newIndex)
      setCardIndex(newIndex);
      setCPositionInCard(posInCard);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [cards, ref]);

  const rows = items.map((card, index, all) => (
    // <Appear key={index}>
    <Card2
      key={index}
      index={index}
      focus={index === cardIndex}
      positionInCard={positionInCard}
      //isLast={cardIndex === all.length - 1}
      count={all.length}
      {...card}
      image={{
        src: card?.relationships?.image?.localFile?.publicURL,
        alt: card?.image?.alt,
      }}
      video={card?.relationships?.video?.localFile?.publicURL}
    />
    // </Appear>
  ));

  return (
    <section className="pt-12 pb-4 lg:py-32 ">
      <div
        className="container lg:max-w-[1280px] space-y-12 lg:space-y-0 relative h-[1412px]"
        ref={ref}
      >
        {rows}
      </div>
    </section>
  );
};

export default IndexPage;

export const query = graphql`
  query ai2($id: String!) {
    node: nodeAi(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      description: field_description2
      pretitle: field_title
      cta: field_cta_1 {
        title
        uri
      }
      section2Desc: field_description3
      section2title: field_title2

      image: field_hero_image {
        alt
      }
      relationships {
        OGImage: field_image {
          localFile {
            publicURL
          }
        }
        image: field_hero_image {
          localFile {
            publicURL
          }
        }
        section1: field_paragraph_repeter {
          ...ParagraphImageLink
        }
        section3: field_paragraphs_blocks {
          ...ParagraphTitleTextImage
        }
        section4: field_paragraph_multi {
          __typename
          paragraph_type {
            drupal_internal__target_id
          }
          description: field_description
          title: field_title
          image: field_image {
            alt
          }
          relationships {
            image: field_image {
              localFile {
                publicURL
              }
            }
            video: field_file {
              localFile {
                publicURL
              }
            }
          }
        }

        section5: field_paragraph_single {
          __typename
          title: field_title
          description: field_description
          image: field_image {
            alt
          }
          relationships {
            image: field_image {
              localFile {
                publicURL
              }
            }
          }
        }
        section6: field_faq {
          ...ParagraphFaq
        }

        video: field_video_file {
          __typename
          localFile {
            publicURL
          }
        }
        thankyou: field_content_block {
          __typename
          cta: field_cta {
            title
            uri
          }
          title: field_title
          image: field_image {
            alt
            title
          }
          relationships {
            image: field_image {
              ...Image
            }
          }
          description: field_description_html {
            processed
          }
        }
      }
    }
  }
`;
