import React from "react";
// import { pipesToSpan } from "../util/common";
// import VisibilitySensor from "react-visibility-sensor";




const ParagraphImageLink = ({data}) => {


    const { items } = data 

  const cards = items.map((item, index) => (
    <div key={index} className="lg:mx-auto px-20">
      <img
        src={item.relationships.image.localFile.publicURL}
        height={item.image.height}
        width={item.image.width}
        alt={item.image.alt}
        className="lg:mx-auto "
      />
    </div>
  ));

  return (
    
        <div className="container max-w-[1280px] space-y-12 ">
          {/* <h2 className="text-3xl font-meduim mx-auto text-center">{title}</h2> */}

          <div
            style={{
              background:
                "linear-gradient(90deg, rgba(45, 54, 67, 0.00) 0%, #2D3643 49.31%, rgba(45, 54, 67, 0.00) 100%)",
            }}
            className="h-[1px]"
          />

          <div className="flex flex-row justify-around  mx-auto  items-end  hidden lg:flex">
            {cards}
          </div>

          <div className="flex flex-row flex-wrap gap-y-9 gap-x-3 justify-around  lg:hidden  items-end ">
            {cards}
          </div>

          <div
            style={{
              background:
                "linear-gradient(90deg, rgba(45, 54, 67, 0.00) 0%, #2D3643 49.31%, rgba(45, 54, 67, 0.00) 100%)",
            }}
            className="h-[1px]"
          />
        </div>
        
      
  );
};


// const Appear = ({ children, delay = 0 }) => {
//   const [isVisible, setIsVisible] = useState(false);
//   const onChange = (flag) => {
//     if (!isVisible) {
//       setIsVisible(flag);
//     }
//   };

//   return (
//     <VisibilitySensor onChange={onChange} partialVisibility>
//       <div
//         className={isVisible ? " opacity-100 " : " opacity-0 "}
//         style={{
//           transition: "all",
//           //transitionProperty:"all",
//           transform: isVisible ? "translateY(0)" : "translateY(100px)",
//           transitionDuration: "1000ms",
//           transitionDelay: `${delay * 200}ms`,
//         }}
//       >
//         <div className=" ">{children}</div>
//       </div>
//     </VisibilitySensor>
//   );
// };



export default ParagraphImageLink;
